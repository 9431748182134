import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button, Modal, Form } from 'react-bootstrap';
import { fetchNewMediations, fetchNotifiedMediations } from 'redux/actions/mediation';
import { fetchMediationMA } from 'redux/actions/mediatorAvailability';
import { saveMMStatus, updateMMStatus, notifyMediationMediator } from 'redux/actions/mediatorMediationStatus';
import moment from 'moment';
import Loader from "components/ui/Loader";

import { Certification } from 'components/ui/Certification';
import { Expertise } from 'components/ui/Expertise';

function ScheduleMediations(props){
  // console.log('schedulemediations called: '+JSON.stringify(props))
  const [modalShow, setModalShow] = useState(false);
  const [bypass, setBypass] = useState();
  const [checkedMediation, setCheckedMediation] = useState(0);
  const [assignedMediator, setAssignedMediator] = useState(0);
  const [mmsId, setMmsId] = useState(0);
  const [selectedIsUnassigned, setSelectedIsUnassigned] = useState(false);
  const [setCurrentMediator] = useState(0);
  const [selectedMediationDate, setSelectedMediationDate] = useState(0);
  const [selectedMediatorMediationDates, setSelectedMediatorMediationDates] = useState(0);
  const [selectedMediatorScheduleConflict, setSelectedMediatorScheduleConflict] = useState(false);

  const { isLoading, newMediations, notifiedMediations, mma, mmStatus } = props;

  // if ( checkedMediation ){
  //   console.log('checkedMediation: ',checkedMediation);
  // }
  // console.log('selectedMediationDate: '+selectedMediationDate);

  useEffect(() => {
    for (var i = 0; i < newMediations.length; i++) {
      if(newMediations[i].id === checkedMediation){
        if (newMediations[i].mm_status.length>0) {
          setAssignedMediator(newMediations[i].mm_status[0].mediator_id);
        }
        // console.log('setting selectedMediationDate1: '+newMediations[i].mediation_date);
        setSelectedMediationDate(newMediations[i].mediation_date);
        // mma.selectedMediationDate(newMediations[i].mediation_date);
      } 
    }
  }, [mma]);

  useEffect(() => {
    setCheckedMediation(0);
    setAssignedMediator(0);
    // setSelectedMediationDate(0);
    setSelectedMediatorMediationDates(0);
    setSelectedMediatorScheduleConflict(0);
    props.fetchNewMediations();
    props.fetchNotifiedMediations();
  }, [mmStatus])

  useEffect(() => {
    props.fetchMediationMA(checkedMediation);
  }, [checkedMediation])

  const getMMA = useCallback( (id, mms_id, mediation_date) => {
    // console.log('getMMA called. id: '+id+', mms_id: '+mms_id, ' ,mediation_date: '+mediation_date)
    // determine if the selected mediation is unassigned yet if there is no mms_id
    setSelectedIsUnassigned(mms_id <= 0);
    setCurrentMediator(mms_id);
    setAssignedMediator(0);
    // console.log('setting selectedMediationDate2: '+mediation_date);
    setSelectedMediationDate(mediation_date);
    // mma.selectedMediationDate(mediation_date);
    setSelectedMediatorScheduleConflict(false);
    if(checkedMediation === id) {
      setMmsId(0);
      setCheckedMediation(0);
      setAssignedMediator(0);
      setSelectedMediatorMediationDates(0);
    } else {
      setMmsId(mms_id);
      setCheckedMediation(id);
      setSelectedMediationDate(mediation_date);
      setAssignedMediator(0);
      setSelectedMediatorMediationDates(0);
    }
  }, [checkedMediation]);

  // mediator checkbox is selected after selecting mediation:
  const saveMediator = useCallback( (id, selected_mediator_mediation_dates) => {
    // console.log('saveMediator called. mediator_id: '+id)
    // console.log('saveMediator called. mediation_id: '+checkedMediation)
    // console.log('saveMediator called. selectedMediationDate: '+selectedMediationDate)
    // console.log('saveMediator called. selected_mediator_mediation_dates: '+JSON.stringify(selected_mediator_mediation_dates))
    setSelectedMediatorScheduleConflict(false);
    if(assignedMediator === id) {
      setAssignedMediator(0);
      // setSelectedMediationDate(0);
    } else {
      setAssignedMediator(id);
      setSelectedMediatorMediationDates(selected_mediator_mediation_dates);
      for (let i = 0; i < selected_mediator_mediation_dates.length; i++) {
        if((selected_mediator_mediation_dates[i].id !== checkedMediation) && (selected_mediator_mediation_dates[i].mediation_date===selectedMediationDate)) {
          setSelectedMediatorScheduleConflict(true);
          break;
        }
      }
    }
  }, [assignedMediator]);

  const saveAssignedMediator = useCallback( () => {
    // console.log('saveAssignedMediator called')
    if (mma[0].id === assignedMediator) {
      const existingMMStatus = newMediations.find(m => m.id === checkedMediation && m.mm_status.length>0);
      if (existingMMStatus) {
        props.updateMMStatus(existingMMStatus.mm_status[0].id, checkedMediation, assignedMediator, '');
      } else {
        props.saveMMStatus(checkedMediation, assignedMediator, '');
      }
    } else {
      setModalShow(true);
    }
  }, [checkedMediation, assignedMediator]);

  const confirmDeletion = useCallback( () => {
    // console.log('confirmDeletion called')
    setModalShow(false);
    const existingMMStatus = newMediations.find(m => m.id === checkedMediation && m.mm_status.length>0);
    if (existingMMStatus) {
      props.updateMMStatus(existingMMStatus.mm_status[0].id, checkedMediation, assignedMediator, bypass);
    } else {
      props.saveMMStatus(checkedMediation, assignedMediator, bypass);
    }
  }, [bypass]);

  const updateBypassReason = useCallback( (reason) => {
    // console.log('updateBypassReason called')
    setBypass(reason);
  }, []);

  // "Genarate Notices" button is pressed for newly assigned mediations
  const callNotifyMediator = useCallback( () => {
    // console.log('callNotifyMediator called')
    props.notifyMediationMediator(mmsId);
  }, [mmsId]);
  
  const newMediationsColumns = useMemo(
    () => [
      {
        Header: 'New Mediations',
        columns: [
          {
            Header: 'Select',
            accessor: data =>  
              <CheckMediation 
                id={data.id} 
                mmsId={data.mm_status.length>0 ? data.mm_status[0].id : 0} 
                selectedMediationDate1={data.mediation_date} 
                checkedId={checkedMediation} 
                check={getMMA}
              />
          },
          {
            Header: 'ADR Case Number',
            accessor: 'adr_case_number',
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Expertise',
            accessor: 'expertises',
            Cell: ({ cell: { value } }) => <Expertise values={value}/>
          },
          {
            Header: 'Mediator',
            accessor: 'mm_status',
            Cell: ({ cell: { value }  }) => {
              if(value[0] && value[0].mediators){
                return value[0].mediators.first_name + ' ' + value[0].mediators.last_name;
              }
              return '';
            }
          }
        ],
      },
    ],
    [checkedMediation]
  )
  
  // let multiLineString = "Available Mediators,Note: Mediators with an asterisk (*) next to their names already have mediations scheduled for this date";
  // let lines = multiLineString.split(',')
  // const linesHTML  = lines.map((line)=><div>{line}</div>);

  const mmaColumns = useMemo(
    () => [
      {
        Header: 'Available Mediators',
        SubHeader: 'subheader',
        columns: [
          {
            Header: 'Select',
            accessor: data =>  <AssignMediator 
                                  id={data.id} 
                                  selectedMediatorMediationDates={data.upcoming_mediations} 
                                  assignedId={assignedMediator} 
                                  check={saveMediator}
                                />
          },
          {
            Header: 'Name',
            // accessor: 'name',0
            accessor: data =>  <PrettyName 
                                name={data.name} 
                                upcoming_mediations={data.upcoming_mediations}
                                selectedMediationDate2={selectedMediationDate}
                              />,
          },
          {
            Header: 'Last Assigned On',
            accessor: 'last_appointed',
            Cell: ({ cell: { value }  }) => {
              return value ? moment(value).format('MM/DD/YYYY') : '';
            }
          },
          {
            Header: 'Comments',
            accessor: 'comments',
          }
        ],
        // Footer: "test footer"
      },
    ],
    [assignedMediator,selectedMediationDate]
  )

  const notifiedMediationsColumns = useMemo(
    () => [
      {
        Header: 'Notified Mediations',
        columns: [
          {
            Header: 'ADR Case Number',
            accessor: 'adr_case_number',
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Expertise',
            accessor: 'expertises',
            Cell: ({ cell: { value } }) => <Expertise values={value}/>
          },
          {
            Header: 'Mediator',
            accessor: 'mm_status',
            Cell: ({ cell: { value }  }) => {
              if(value[0] && value[0].mediators){
                return value[0].mediators.first_name + ' ' + value[0].mediators.last_name;
              }
              return '';
            }
          }
        ],
      },
    ],
    []
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Schedule Mediators / Mediations</h3>
          <Spacer size={0} />
          <div className="table-responsive">
            <Table 
              columns={newMediationsColumns} 
              data={newMediations}
              tableHeader={true} />
            <Spacer size={10} />
            <Button className="btn-save" variant="primary-sm" size="sm" onClick={saveAssignedMediator} disabled={assignedMediator===0} >
              Save Changes
            </Button>
            <Button className="btn-save" variant="primary-sm" size="sm" onClick={callNotifyMediator} disabled={mmsId===0} >
              Generate Notices
            </Button>
            <Spacer size={10} />
            Note: Mediators with an asterisk (<label class="conflict-warning-character">*</label>) next to their names already have mediations scheduled for this date
            <Table 
              columns={mmaColumns} 
              data={mma}
              tableHeader={true}/>
            <Spacer size={10} />
            <Table 
              columns={notifiedMediationsColumns} 
              data={notifiedMediations}
              tableHeader={true} />
          </div>
          <ConfirmationModal
            show={modalShow}
            selectedIsUnassigned={selectedIsUnassigned}
            selectedMediatorScheduleConflict={selectedMediatorScheduleConflict}
            selectedMediatorMediationDates={selectedMediatorMediationDates}
            onHide={() => setModalShow(false)}
            bypassReason={updateBypassReason}
            onConfirm={confirmDeletion}
          />
        </Container>
      </div>
    </>
  )
}

const CheckMediation = (props) => {
  return(
    <>
      <input type="checkbox" id="mediation" checked={props.id === props.checkedId} onChange={() => props.check(props.id, props.mmsId, props.selectedMediationDate1)}/>
    </>
  )
}

const AssignMediator = (props) => {
  return(
    <>
      <input type="checkbox" id="mediator" checked={props.id === props.assignedId} onChange={() => props.check(props.id, props.selectedMediatorMediationDates)} />
    </>
  )
}

const PrettyName = (props) => {
  // console.log('PrettyName props: ',JSON.stringify(props));
  var schedule_conflict = false;
  for ( var i = 0 ; i < props.upcoming_mediations.length ; i++ ){
    if ( props.selectedMediationDate2 === props.upcoming_mediations[i].mediation_date ){
      schedule_conflict = true;
      break;
    }
  }

  if ( schedule_conflict ){
    return (
      <label
        className="schedule-conflict"
      > {props.name}
      </label>
    );
  } else {
    return (
      <label> {props.name}
      </label>
    );
  }
}

const ConfirmationModal = (props) => {
  // console.log('confirmationModal props: '+JSON.stringify(props))
  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Mediator Out of Order</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {`Selected mediator is not the next available mediator. Please state the reason for bypassing other available mediators.`}
        </p>
        <Form.Check 
          type='radio'
          id='1'
          name='skip'
          label='Expertise Needed'
          onChange={() => props.bypassReason('Expertise Needed')}
        />
        <Form.Check 
          type='radio'
          id='2'
          name='skip'
          label='Requested by Attorneys'
          onChange={() => props.bypassReason('Requested by Attorneys')}
        />
        {/* {!props.selectedIsUnassigned && */}

          <Form.Check 
            type='radio'
            id='3'
            name='skip'
            label='No Response From Assigned Mediator'
            onChange={() => props.bypassReason('No Response From Assigned Mediator')}
          />
        {/* } */}
        {props.selectedMediatorScheduleConflict &&
          <Form.Check 
          type='radio'
          id='4'
          name='skip'
          label='Additional Mediation'
          onChange={() => props.bypassReason('Additional Mediation')}
        />
        }
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" onClick={() => props.onConfirm()}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_NEW_MEDIATIONS') 
    || state.mediationReducer.isLoading.includes('GET_NOTIFIED_MEDIATIONS')
    || state.mediatorAvailabilityReducer.isLoading
    || state.mmStatusReducer.isLoading.includes('SAVE_MEDIATION_MEDIATOR')
    || state.mmStatusReducer.isLoading.includes('UPDATE_MEDIATION_MEDIATOR')
    || state.mmStatusReducer.isLoading.includes('NOTIFY_MEDIATOR');
  const mmStatus = state.mmStatusReducer.mmStatus;
  const newMediations = state.mediationReducer.newMediations;
  const notifiedMediations = state.mediationReducer.notifiedMediations;
  const mma = state.mediatorAvailabilityReducer.mma;
  // const selectedMed = state.mediatorAvailabilityReducer.mma;
  return { isLoading, newMediations, notifiedMediations, mma, mmStatus }
}

export default connect(
  mapStateToProps,
  {
    fetchNewMediations,
    fetchNotifiedMediations,
    fetchMediationMA,
    saveMMStatus,
    updateMMStatus,
    notifyMediationMediator
  }
)(ScheduleMediations)