import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button, Image } from 'react-bootstrap';
import { fetchMediators, modifyMediatorStatus } from 'redux/actions/mediator';
import { fetchMediatorMediationsReport, fetchMediatorsReport } from 'redux/actions/report';
import calendar from 'images/calendar.png';
import checkImage from 'images/check.png';
import uncheckImage from 'images/uncheck.png';
import Loader from "components/ui/Loader";
import { Certification } from 'components/ui/Certification';
import { Expertise } from 'components/ui/Expertise';

function ViewAllMediators(props){
  const [checkedMediator, setCheckedMediator] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const { isLoading, mediators, mediator } = props;

  useEffect(() => {
    for (let i = 0; i < mediators.length; i++) {
      if(mediators[i].id === checkedMediator) {
        setIsActive(mediators[i].is_active);
      }
    }
  }, [checkedMediator, mediators])

  useEffect(() => {
    props.fetchMediators();
  }, [])

  useEffect(() => {
    setCheckedMediator(0);
    if(mediator !== '')
      props.fetchMediators();
  }, [mediator])

  const updateSelectedMediator = useCallback( id => {
    if(checkedMediator === id) {
      setCheckedMediator(0);
    } else {
      setCheckedMediator(id);
    }
  }, [checkedMediator]);

  const editMediator = useCallback( () => {
    props.history.push(`/admin/mediator/edit?id=${checkedMediator}`);
  }, [checkedMediator]);

  const changeMediatorStatus = useCallback( () => {
    props.modifyMediatorStatus(checkedMediator, isActive);
  }, [checkedMediator, isActive]);

  const getReport = useCallback(() => {
    props.fetchMediatorMediationsReport(checkedMediator);
  }, [checkedMediator]);

  const getMediatorReport = useCallback(() => {
    props.fetchMediatorsReport();
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Select',
            accessor: 'id',
            Cell: ({cell: { value }}) => <CheckMediator id={value} checkedId={checkedMediator} check={updateSelectedMediator}/>
          },
          {
            Header: 'Name',
            accessor: data => <ClickName id={data.id} name={data.name} check={(id) => {props.history.push(`/admin/mediator/edit?id=${id}`)}}/>
          },
          {
            Header: 'Certification',
            accessor: 'certifications',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Expertise',
            accessor: 'expertises',
            Cell: ({ cell: { value } }) => <Expertise values={value}/>
          },
          {
            Header: 'Last Appointment',
            accessor: 'last_appointment',
            Cell: ({ cell: { value }  }) => {
              return value ? moment(value).format('MM/DD/YYYY') : '';
            }
          },
          {
            Header: 'Next Availability',
            accessor: 'next_availability',
            Cell: ({ cell: { value }  }) => {
              return value ? moment(value).format('MM/DD/YYYY'): '';
            }
          },
          {
            Header: ' ',
            accessor: data => <CheckAvailability id={data.id} check={(id) => {props.history.push(`/admin/mediator/availability?id=${id}`)}} />
          },
          {
            Header: 'Active',
            accessor: 'is_active',
            Cell: ({ cell: { value } }) => <CheckStatus isActive={value}/>
          }
        ],
      },
    ],
    [checkedMediator]
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>All Mediators</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={mediators}
              tableHeader={false} />
          </div>
          <Spacer size={10} />
          <Button className="btn-save" variant="primary-sm" size="sm" onClick={editMediator} disabled={checkedMediator===0}>
              Edit Profile
          </Button>
          <Button className="btn-save" variant="primary-sm" size="sm" onClick={changeMediatorStatus} disabled={checkedMediator===0}>
            {isActive === 1 || isActive === '1' ? 'Disable Mediator': 'Enable Mediator'}
          </Button>
          <Button className="btn-save" variant="primary-sm" size="sm" disabled={checkedMediator===0} onClick={getReport} >
              Export
          </Button>
          <Button className="btn-save" variant="primary-sm" size="sm" onClick={getMediatorReport} >
              Export Mediators
          </Button>
        </Container>
      </div>
    </>
  )
}

const CheckMediator = (props) => {
  return(
    <>
      <input type="checkbox" id="mediator" checked={props.id === props.checkedId} onChange={() => props.check(props.id)}/>
    </>
  )
}

const ClickName = (props) => {
  return(
    <>
      <div style={{cursor: "pointer"}} onClick={() => props.check(props.id)}>{props.name}</div>
    </>
  )
}

const CheckAvailability = (props) => {
  return(
    <>
      <Image className="checkuncheck" src={calendar} onClick={() => props.check(props.id)}/>
    </>
  )
}

const CheckStatus = (props) => {
  return(
    <>
      { props.isActive === 1 || props.isActive === '1' ?
        <Image className="checkuncheck" src={checkImage}/> : 
        <Image className="checkuncheck" src={uncheckImage}/> }
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = state.mediatorReducer.isLoading.includes('GET_MEDIATORS')
    || state.mediatorReducer.isLoading.includes('MEDIATOR_STATUS');
  const mediators = state.mediatorReducer.mediators;
  const mediator = state.mediatorReducer.mediator;
  return { isLoading, mediators, mediator }
}

export default connect(
  mapStateToProps,
  {
    fetchMediators,
    fetchMediatorMediationsReport,
    fetchMediatorsReport,
    modifyMediatorStatus
  }
)(ViewAllMediators)
