import { actionTypes } from './actionTypes';
import axios from 'axios';
import constants from 'config/constants';
import { getValueFromLocal } from 'util/storageUtil';
import moment from 'moment';

export function createMediator() {
  return {
    type: actionTypes.CREATE_MEDIATOR
  }
}

export function createMediatorSuccess(mediator) {
  return {
    type: actionTypes.CREATE_MEDIATOR_SUCCESS,
    payload: mediator
  }
}

export function createMediatorFailure(error) {
  return {
    type: actionTypes.CREATE_MEDIATOR_FAILURE,
    payload: error
  }
}

export function getMediator() {
  return {
    type: actionTypes.GET_MEDIATOR
  }
}
    
export function getMediatorSuccess(mediator) {
  return {
    type: actionTypes.GET_MEDIATOR_SUCCESS,
    payload: mediator
  }
}

export function getMediatorFailure(error) {
  return {
    type: actionTypes.GET_MEDIATOR_FAILURE,
    payload: error
  }
}

export function getMediatorImage() {
  return {
    type: actionTypes.GET_MEDIATOR_IMAGE
  }
}
    
export function getMediatorImageSuccess(image) {
  return {
    type: actionTypes.GET_MEDIATOR_IMAGE_SUCCESS,
    payload: image
  }
}

export function getMediatorImageFailure(error) {
  return {
    type: actionTypes.GET_MEDIATOR_IMAGE_FAILURE,
    payload: error
  }
}

export function getMediators() {
  return {
    type: actionTypes.GET_MEDIATORS
  }
}
    
export function getMediatorsSuccess(mediators) {
  return {
    type: actionTypes.GET_MEDIATORS_SUCCESS,
    payload: mediators
  }
}

export function getMediatorsFailure(error) {
  return {
    type: actionTypes.GET_MEDIATORS_FAILURE,
    payload: error
  }
}

export function searchMediators() {
  return {
    type: actionTypes.SEARCH_MEDIATOR,
  }
}

export function searchMediatorsSuccess(searchMediators) {
  return {
    type: actionTypes.SEARCH_MEDIATOR_SUCCESS,
    payload: searchMediators
  }
}

export function searchMediatorsFailure(error) {
  return {
    type: actionTypes.SEARCH_MEDIATOR_FAILURE,
    payload: error
  }
}

export function uploadImage() {
  return {
    type: actionTypes.UPLOAD_IMAGE
  }
}

export function uploadImageSuccess(mediator) {
  return {
    type: actionTypes.UPLOAD_IMAGE_SUCCESS,
    payload: mediator
  }
}

export function uploadImageFailure(error) {
  return {
    type: actionTypes.UPLOAD_IMAGE_FAILURE,
    payload: error
  }
}

export function updateMediator() {
  return {
    type: actionTypes.UPDATE_MEDIATOR
  }
}

export function updateMediatorSuccess(mediator) {
  return {
    type: actionTypes.UPDATE_MEDIATOR_SUCCESS,
    payload: mediator
  }
}

export function updateMediatorFailure(error) {
  return {
    type: actionTypes.UPDATE_MEDIATOR_FAILURE,
    payload: error
  }
}

export function updateMediatorPassword() {
  return {
    type: actionTypes.UPDATE_MEDIATOR_PASSWORD
  }
}

export function updateMediatorPasswordSuccess(mediator) {
  return {
    type: actionTypes.UPDATE_MEDIATOR_PASSWORD_SUCCESS,
    payload: mediator
  }
}

export function updateMediatorPasswordFailure(error) {
  return {
    type: actionTypes.UPDATE_MEDIATOR_PASSWORD_FAILURE,
    payload: error
  }
}

export function mediatorStatus() {
  return {
    type: actionTypes.MEDIATOR_STATUS
  }
}

export function mediatorStatusSuccess(mediator) {
  return {
    type: actionTypes.MEDIATOR_STATUS_SUCCESS,
    payload: mediator
  }
}

export function mediatorStatusFailure(error) {
  return {
    type: actionTypes.MEDIATOR_STATUS_FAILURE,
    payload: error
  }
}

export function deleteMediator() {
  return {
    type: actionTypes.DELETE_MEDIATOR
  }
}
  
export function deleteMediatorSuccess() {
  return {
    type: actionTypes.DELETE_MEDIATOR_SUCCESS
  }
}
  
export function deleteMediatorFailure(error) {
  return {
    type: actionTypes.DELETE_MEDIATOR_FAILURE,
    payload: error
  }
}

export function allowMediatorPasswordChange() {
  return {
    type: actionTypes.ALLOW_MEDIATOR_PASSWORD_CHANGE
  }
}

export function clearExistingMediator() {
  return {
    type: actionTypes.CLEAR_EXISTING_MEDIATOR
  }
}

export const uploadMediatorImage = (id, data) => {
  return dispatch => {
    dispatch(uploadImage());
    axios
      .post(`${constants.url}/mediators/image/${id}`, data,
        { headers: {
          'Content-Type': 'multipart/form-data', 
          Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` 
        } })
      .then(res => dispatch(uploadImageSuccess(res)))
      .catch(err => dispatch(uploadImageFailure(err)))
  }
}

export const addMediator = (mediator) => {
  return dispatch => {
    dispatch(createMediator());
    axios
      .post(`${constants.url}/mediators`, mediator,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(createMediatorSuccess(res)))
      .catch(err => dispatch(createMediatorFailure(err)))
  }
}

export const fetchMediator = (id) => {
  return dispatch => {
    dispatch(getMediator());
    axios
      .get(`${constants.url}/mediators/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediatorSuccess(res.data)))
      .catch(err => dispatch(getMediatorFailure(err)))
  }
}

export const downloadMediatorImage = (id) => {
  return dispatch => {
    dispatch(getMediatorImage());
    axios
      .get(`${constants.url}/mediators/image/${id}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediatorImageSuccess(res.data)))
      .catch(err => dispatch(getMediatorImageFailure(err)))
  }
}

export const fetchMediators = () => {
  return dispatch => {
    dispatch(getMediators());
    axios
      .get(`${constants.url}/mediators`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(getMediatorsSuccess(res.data)))
      .catch(err => dispatch(getMediatorsFailure(err)))
  }
}

export const modifyMediator = (id, mediator) => {
  return dispatch => {
    dispatch(updateMediator());
    axios
      .put(`${constants.url}/mediators/${id}`, mediator,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateMediatorSuccess(res)))
      .catch(err => dispatch(updateMediatorFailure(err)))
  }
}

export const modifySelfMediator = (id, mediator) => {
  return dispatch => {
    dispatch(updateMediator());
    axios
      .put(`${constants.url}/mediators/self/${id}`, mediator,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateMediatorSuccess(res)))
      .catch(err => dispatch(updateMediatorFailure(err)))
  }
}

export const modifyMediatorPassword = (id, data) => {
  return dispatch => {
    dispatch(updateMediatorPassword());
    axios
      .put(`${constants.url}/mediators/password/${id}`, data ,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(updateMediatorPasswordSuccess(res)))
      .catch(err => dispatch(updateMediatorPasswordFailure(err)))
  }
}

export const resetMediatorForgotPassword = (id, data, token) => {
  return dispatch => {
    dispatch(updateMediatorPassword());
    axios
      .put(`${constants.url}/mediators/password/${id}`, data ,
        { headers: { Authorization: `Bearer ${token}` } })
      .then(res => dispatch(updateMediatorPasswordSuccess(res)))
      .catch(err => dispatch(updateMediatorPasswordFailure(err)))
  }
}

export const modifyMediatorStatus = (id, is_active) => {
  return dispatch => {
    dispatch(mediatorStatus());
    axios
      .put(`${constants.url}/mediators/status/${id}`, { is_active: is_active === 1 || is_active === '1' ? 0 : 1 },
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(mediatorStatusSuccess(res)))
      .catch(err => dispatch(mediatorStatusFailure(err)))
  }
}

export const removeMediator = (id) => {
  return dispatch => {
    dispatch(deleteMediator());
    axios
      .delete(`${constants.url}/mediators/${id}`,
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(deleteMediatorSuccess()))
      .catch(err => dispatch(deleteMediatorFailure(err)))
  }
}

export const searchMediator = (name_id, certification_ids, expertise_ids, start_date, end_date) => {
  let query;
  if(name_id && name_id!==''){
    query=`name_id=${name_id}`
  }
  if(certification_ids && certification_ids!==''){
    query = query ? query+`&certification_ids=${certification_ids}` : `certification_ids=${certification_ids}`;
  }
  if(expertise_ids && expertise_ids!==''){
    query = query ? query+`&expertise_ids=${expertise_ids}` : `expertise_ids=${expertise_ids}`;
  }
  if(start_date && start_date!==''){
    query = query ?  query+`&start_date=${moment(start_date).format('YYYY/MM/DD')}` : `start_date=${moment(start_date).format('YYYY/MM/DD')}`;
  }
  if(end_date && end_date!==''){
    query = query ? query+`&end_date=${moment(end_date).format('YYYY/MM/DD')}` : `end_date=${moment(end_date).format('YYYY/MM/DD')}`;
  }
  return dispatch => {
    dispatch(searchMediators());
    axios
      .get(`${constants.url}/mediators/search?${query}`, 
        { headers: { Authorization: `Bearer ${getValueFromLocal(constants['JWT_ACCESS_KEY'])}` } })
      .then(res => dispatch(searchMediatorsSuccess(res.data)))
      .catch(err => dispatch(searchMediatorsFailure(err)))
  }
}
