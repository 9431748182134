import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { reset, reduxForm, Field } from 'redux-form';
import { useLocation } from "react-router-dom";
import { required, passwordsMatch } from 'util/validationUtil';
import { reduxFormInput } from 'components/form/ReduxForm';
import { checkPasswordToken } from 'redux/actions/auth';
import { resetAdminForgotPassword } from 'redux/actions/administrator';
import { resetMediatorForgotPassword } from 'redux/actions/mediator';
import { resetSchedulerForgotPassword } from 'redux/actions/scheduler';

import Header from 'components/layout/LoginHeader';
import Spacer from 'components/layout/Spacer';

import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/ui/Loader";

const PasswordReset = (props) => {
  let query = useQuery();
  const token = query.get("t");

  let user = '';
  const path = props.history.location.pathname;
  if(path === '/admin/reset') {
    user = 'administrator';
  } else if(path === '/mediator/reset') {
    user = 'mediator';
  } else if(path === '/scheduler/reset') {
    user = 'scheduler';
  }

  const { handleSubmit, isLoggingIn, userId, isResetSuccessful } = props;

  if(isResetSuccessful) {
    props.dispatch(reset('passwordReset'));
    props.dispatch({ type: 'USER_LOGOUT' });
    props.history.push('/');
  }

  useEffect(() => {
    props.checkPasswordToken(user, token);
  }, [])

  const submitFn = useCallback( data => {
    if(user === 'administrator') {
      props.resetAdminForgotPassword(userId, data, token);
    } else if(user === 'mediator') {
      props.resetMediatorForgotPassword(userId, data, token);
    } else if(user === 'scheduler') {
      props.resetSchedulerForgotPassword(userId, data, token);
    }
  }, [userId, token]);

  return(
    <>
      <Header type={'Password Reset'} />
      { isLoggingIn && <Loader /> }
      <main className="fc-content" id="login">
        <Spacer size={100} />
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs="12" md="8">
              <Form onSubmit={handleSubmit(submitFn)}>
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="3">
                        Password:
                  </Form.Label>
                  <Col xs="12" sm="9">
                    <Field 
                      className="form-control" 
                      component={reduxFormInput}
                      validate={[required]}
                      name="password" 
                      type="password" 
                      autocomplete="new-password"
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="align-items-center">
                  <Form.Label column xs="12" sm="3">
                        Confirm Password:
                  </Form.Label>
                  <Col xs="12" sm="9">
                    <Field 
                      className="form-control" 
                      component={reduxFormInput}
                      validate={[required, passwordsMatch]}
                      name="confirm_password" 
                      type="password" 
                    />
                  </Col>
                </Form.Group>

                <Row>
                  <Col className="text-right">
                    <Button type="submit" className="btn-login" variant="secondary" size="lg">
                        Login
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </main>
    </>
  )
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const mapStateToProps = state => {
  const isLoggingIn = state.authReducer.isLoggingIn 
  || state.mediatorReducer.isLoading.includes('UPDATE_MEDIATOR_PASSWORD')
  || state.administratorReducer.isCalling.includes('UPDATE_ADMIN_PASSWORD')
  || state.schedulerReducer.isCalling.includes('UPDATE_SCHEDULER_PASSWORD');
  const isResetSuccessful = state.administratorReducer.callSuccessful.includes('UPDATE_ADMIN_PASSWORD')
  || state.mediatorReducer.callSuccessful.includes('UPDATE_MEDIATOR_PASSWORD')
  || state.schedulerReducer.callSuccessful.includes('UPDATE_SCHEDULER_PASSWORD');
  const userId = state.authReducer.userId;
  return { isLoggingIn, userId, isResetSuccessful };
}

const mapDispatchToProps = {
  checkPasswordToken,
  resetAdminForgotPassword,
  resetMediatorForgotPassword,
  resetSchedulerForgotPassword
}

const connectedReduxForm = reduxForm({
  form: 'passwordReset',
  enableReinitialize: true,
})(PasswordReset);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectedReduxForm)
