import React from 'react';
import { connect } from 'react-redux';
import { reset, reduxForm } from 'redux-form';
import queryString from 'query-string';
import { authenticateUser } from 'redux/actions/auth';
import { checkAuthentication } from 'redux/selectors';

import Header from 'components/layout/LoginHeader';
import Spacer from 'components/layout/Spacer';

import { Link, Redirect } from 'react-router-dom';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Loader from "components/ui/Loader";
import { toast } from 'react-toastify';

class AdminLogin extends React.Component {

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    if (this.state && this.state.email && this.state.password) {
      const { email, password } = this.state;
      this.props.login('administrator', email, password);
    }
  }

  render() {
    const { isLoggingIn, isAuthenticated } = this.props;
    
    if(isAuthenticated) {
      this.props.dispatch(reset('adminLogin'));
      if(this.props.location.search!==""){
        const path = queryString.parse(this.props.location.search);
        return <Redirect to={`${path.redirect}`} />
      }
      return <Redirect to='/admin/panel' />
      // this.props.history.push('/admin/panel');
    }
    
    return(
      <>
        <Header type={'Administrator Portal'} />
        { isLoggingIn && <Loader /> }
        <main className="fc-content" id="login">
          <Spacer size={100} />
          <Container>
            <Row className="justify-content-center align-items-center">
              <Col xs="12" md="8">
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group as={Row} controlId="email">
                    <Form.Label className="pr-0 pt-0 pb-0" column sm="3">
                      Email
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control type="email" onChange={this.handleChange} required/>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} controlId="password">
                    <Form.Label className="pr-0 pt-0 pb-0" column sm="3">
                      Password
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control type="password" onChange={this.handleChange} required/>
                    </Col>
                  </Form.Group>

                  <Row>
                    <Col className="text-right">
                      <Link to="/admin/login/forgot">Forgot Password</Link>
                      <Spacer size={15} />
                    </Col>
                  </Row>

                  <Row>
                    <Col className="text-right">
                      <Button type="submit" className="btn-login" variant="secondary" size="lg">
                        Login
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </main>
      </>
    )
  }
}

const mapStateToProps = state => {
  if (state.authReducer.loginFailed) {
    toast.error("Email and Password don't match!");
  }
  const isLoggingIn = state.authReducer.isLoggingIn;
  const isAuthenticated = checkAuthentication(state);
  return { isLoggingIn, isAuthenticated };
}

const mapDispatchToProps = {
  login: authenticateUser
}

const connectedReduxForm = reduxForm({
  form: 'adminLogin',
  enableReinitialize: true,
})(AdminLogin);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(connectedReduxForm)
