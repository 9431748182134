import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import Loader from "components/ui/Loader";
import { searchMediator, modifyMediatorStatus } from 'redux/actions/mediator';
import { Container, Row, Col, Form, Button, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import calendar from 'images/calendar.png';
import moment from 'moment';
import { isCertificationLoading, isExpertiseLoading } from 'redux/selectors';
import { fetchCertifications } from 'redux/actions/certification';
import { fetchExpertises } from 'redux/actions/expertise';
import DatePicker from "react-datepicker";
import { Certification } from 'components/ui/Certification';
import { Expertise } from 'components/ui/Expertise';

function ManageMediators(props) {

  const [checkedMediator, setCheckedMediator] = useState(0);
  const [nameId, setNameId] = useState();
  const [certificationIds, setCertificationIds] = useState([]);
  const [expertiseIds, setExpertiseIds] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [mediatorsCombined, setMediatorsCombined] = useState([]);

  const { isLoading, certifications, expertises, mediators } = props;

  let isActive = false;
  useEffect(() => {
    if(mediators.length > 0) {
      let temp = mediators[0];
      for(let x = 1; x < mediators.length; x++) {
        temp = intersection(temp, mediators[x], true);
      }
      setMediatorsCombined(temp);
      for (let i = 0; i < temp.length; i++) {
        if(temp[i].id === checkedMediator) {
          isActive = temp[i].is_active;
        }
      }
    }
  }, [mediators]);

  useEffect(() => {
    props.fetchCertifications();
    props.fetchExpertises();
  }, []);

  const updateSelectedMediator = useCallback( id => {
    if(checkedMediator === id) {
      setCheckedMediator(0);
    } else {
      setCheckedMediator(id);
    }    
  }, [checkedMediator]);

  const handleCertificateChange = useCallback( e => {
    if(e.target.checked===true){
      setCertificationIds([...certificationIds, e.target.name]);
    } else {
      setCertificationIds(certificationIds.filter(item => item !== e.target.name));
    }
  }, [certificationIds]);

  const handleExpertiseChange = useCallback( e => {
    if(e.target.checked===true){
      setExpertiseIds([...expertiseIds, e.target.name]);
    } else {
      setExpertiseIds(expertiseIds.filter(item => item !== e.target.name));
    }
  }, [expertiseIds]);

  const changeMediatorStatus = useCallback( () => {
    props.modifyMediatorStatus(checkedMediator, isActive);
  }, [checkedMediator]);

  const handleSearch = useCallback( () => {
    const certification_ids = certificationIds.join();
    const expertise_ids = expertiseIds.join();
    props.searchMediator(nameId, certification_ids, expertise_ids, startDate, endDate);
  }, [nameId, certificationIds, expertiseIds, startDate, endDate]);

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Select',
            accessor: 'id',
            Cell: ({cell: { value }}) => <CheckMediator id={value} checkedId={checkedMediator} check={updateSelectedMediator}/>
          },
          {
            Header: 'Name',
            accessor: data => <ClickName id={data.id} name={data.name} check={(id) => {props.history.push(`/admin/mediator/edit?id=${id}`)}}/>
          },
          {
            Header: 'Certification',
            accessor: 'certifications',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Expertise',
            accessor: 'expertises',
            Cell: ({ cell: { value } }) => <Expertise values={value}/>
          },
          {
            Header: 'Last Appointment',
            accessor: 'last_appointment',
            Cell: ({ cell: { value }  }) => {
              return value ? moment(value).format('MM/DD/YYYY') : '';
            }
          },
          {
            Header: 'Next Appointment',
            accessor: 'next_availability',
            Cell: ({ cell: { value }  }) => {
              return value ? moment(value).format('MM/DD/YYYY') : '';
            }
          },
          {
            Header: ' ',
            accessor: data => <CheckAvailability id={data.id} check={(id) => {props.history.push(`/admin/mediator/availability?id=${id}`)}} />
          },
          {
            Header: 'Comment',
            accessor: 'comments'
          }
        ],
      },
    ],
    [checkedMediator]
  )

  return(
    <> 
      { isLoading && <Loader /> }
      <main className="fc-content">
        <Spacer size={30} />
        <Container fluid>
          <Row>
            <Col xs="12">
              <h2>Manage Mediators</h2>
              <Spacer size={15} />
            </Col>
            <Col xs="12" sm="6">
              <Button as={Link} to="/admin/all/mediators" variant="secondary" size="lg">
                  View All Mediators
              </Button>    
              <Spacer size={20} />              
            </Col>
            <Col xs="12" sm="6">
              <Button as={Link} to="/admin/mediator" variant="secondary" size="lg">
                  Create New Mediator
              </Button>         
              <Spacer size={20} />         
            </Col>
            <Col xs="12">
              <div className="form-blur"> 
                <Col xs="12">
                  <h3>Search by:</h3>
                </Col>
                <Form>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="3" className="text-left">
                        Last Name or ID:
                    </Form.Label>
                    <Col xs="5">
                      <Form.Control name="SEARCH" type="text" onChange={e => setNameId(e.target.value)} placeholder="Last name or ID..." />
                    </Col>
                    <Col xs="4"></Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Col xs="12" sm="4">
                      <Form.Label>
                          Case Category(s):
                      </Form.Label>
                      {certifications.map(certificate => (
                        <Form.Check 
                          type='checkbox'
                          onChange={handleCertificateChange}
                          label={certificate.certification_type}
                          key={certificate.id}
                          name={certificate.id}
                        />
                      ))}
                    </Col>
                    <Col xs="12" sm="4">
                      <Form.Label>
                          Expertise(s):
                      </Form.Label>
                      {expertises.map( expertise => (
                        <Form.Check 
                          type='checkbox'
                          onChange={handleExpertiseChange}
                          label={expertise.name}
                          key={expertise.id}
                          name={expertise.id}
                        />
                      ))}
                    </Col>
                    <Col xs="12" sm="4">
                      <Form.Label>
                          Date Availability:
                      </Form.Label>
                      <p>Start:</p>
                      <DatePicker className="date-picker" dateFormat="MM/dd/yyyy" selected={startDate} onChange={date => setStartDate(date)} />
                      <Spacer size={5} />
                      <p>End:</p>
                      <DatePicker className="date-picker" dateFormat="MM/dd/yyyy" selected={endDate} onChange={date => setEndDate(date)} />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={20} />
                  <Button variant="primary-alt" size="lg" onClick={handleSearch} >
                    Search
                  </Button>
                  <Spacer size={20} />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="table-responsive">
            { mediatorsCombined && mediatorsCombined.length>0 && <Table 
              columns={columns} 
              data={mediatorsCombined}
              tableHeader={false} />
            }
          </div>
          <Spacer size={5} />
          <div>
            { mediatorsCombined && mediatorsCombined.length>0 && <Button className="btn-save" variant="primary-sm" size="sm" onClick={changeMediatorStatus} disabled={checkedMediator===0}>
              {isActive ? 'Disable Mediator': 'Enable Mediator'}
            </Button>}
          </div>
          <Spacer size={5} />
        </Container>
      </main>
    </>
  )
}

const intersection = (list1, list2, isUnion) =>
  list1.filter(
    (set => a => isUnion === set.has(a.id))(new Set(list2.map(b => b.id)))
  );

const CheckMediator = (props) => {
  return(
    <>
      <input type="checkbox" id="mediator" checked={props.id === props.checkedId} onChange={() => props.check(props.id)}/>
    </>
  )
}

const ClickName = (props) => {
  return(
    <>
      <div style={{cursor: "pointer"}} onClick={() => props.check(props.id)}>{props.name}</div>
    </>
  )
}

const CheckAvailability = (props) => {
  return(
    <>
      <Image className="checkuncheck" src={calendar} onClick={() => props.check(props.id)}/>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = isCertificationLoading(state) 
    || isExpertiseLoading(state)
    || state.mediatorReducer.isLoading.includes('SEARCH_MEDIATOR')
    || state.mediatorReducer.isLoading.includes('MEDIATOR_STATUS');
  const certifications = state.certificationReducer.certifications;
  const expertises = state.expertiseReducer.expertises;
  const mediators = state.mediatorReducer.searchMediators;
  const mediator = state.mediatorReducer.mediator;
  return { isLoading, certifications, expertises, mediators, mediator };
};

export default connect(
  mapStateToProps, { 
    fetchCertifications,
    fetchExpertises, 
    modifyMediatorStatus,
    searchMediator 
  }
)(ManageMediators)
