import React, { useMemo, useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Spacer from 'components/layout/Spacer';
import Table from 'components/ui/Table';
import { Container, Button } from 'react-bootstrap';
import { fetchAssignedMediations } from 'redux/actions/mediation';
import { cancelConfirmedMediation } from 'redux/actions/mediatorMediationStatus';
import { fetchConfirmedMediationsReport } from 'redux/actions/report';
import Loader from "components/ui/Loader";

import { Certification } from 'components/ui/Certification';

function AssignedMediations(props){
  const [checkedMediation, setCheckedMediation] = useState(0);
  const { isLoading, assignedMediations, cancelledMediationStatus } = props;

  useEffect(() => {
    props.fetchAssignedMediations();
  }, [])

  useEffect(() => {
    setCheckedMediation(0);
    if(cancelledMediationStatus) props.fetchAssignedMediations();
  }, [cancelledMediationStatus]);

  const updateSelectedMediation = useCallback( id => {
    if(checkedMediation === id) {
      setCheckedMediation(0);
    } else {
      setCheckedMediation(id);
    }
  }, [checkedMediation]);

  const cancelMediation = useCallback( () => {
    props.cancelConfirmedMediation(checkedMediation);
  }, [checkedMediation]);

  const getReport = useCallback(() => {
    props.fetchConfirmedMediationsReport();
  }, []);
  
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        columns: [
          {
            Header: 'Select',
            accessor: 'mm_status[0].id',
            Cell: ({cell: { value }}) => <CheckMediation id={value} checkedId={checkedMediation} check={updateSelectedMediation}/>
          },
          {
            Header: 'ADR Case Number',
            accessor: 'adr_case_number',
          },
          {
            Header: 'Date',
            accessor: 'mediation_date',
            Cell: ({ cell: { value }  }) => {
              return moment(value).format('MM/DD/YYYY');
            }
          },
          {
            Header: 'Time',
            accessor: 'mediation_time',
            Cell: ({ cell: { value }  }) => {
              return moment(value, "HH:mm:ss").format('hh:mm a');
            }
          },
          {
            Header: 'Certification',
            accessor: 'certificates',
            Cell: ({ cell: { value } }) => <Certification values={value}/>
          },
          {
            Header: 'Mediator',
            accessor: 'mm_status[0].mediators',
            Cell: ({ cell: { value }  }) => {
              if (value)
                return value.first_name + ' ' + value.last_name;
              return '';
            }
          },
          {
            Header: 'Phone',
            accessor: 'mm_status[0].mediators.phone_number',
            Cell: ({ cell: { value }  }) => {
              if(value)
                return value.slice(0,3) + "-" + value.slice(3,6) + "-" + value.slice(6);
              return ''
            }
          },
        ],
      },
    ],
    [checkedMediation]
  )

  return(
    <>
      { isLoading && <Loader /> }
      <div className="fc-content">
        <Spacer size={20} />
        <Container fluid>
          <h3>Confirmed Mediations</h3>
          <Spacer size={3} />
          <div className="table-responsive">
            <Table 
              columns={columns} 
              data={assignedMediations}
              tableHeader={false} />
          </div>
          <Spacer size={15} />
          <Button className="btn-second-alt" variant="secondary-alt" onClick={cancelMediation} disabled={checkedMediation===0} >
            Cancel Confirmed Mediation
          </Button>
          <Button className="btn-second-alt" variant="secondary-alt" onClick={getReport} >
            Export
          </Button>
        </Container>
      </div>
    </>
  )
}

const CheckMediation = (props) => {
  return(
    <>
      <input type="checkbox" id="mediation" checked={props.id === props.checkedId} onChange={() => props.check(props.id)}/>
    </>
  )
}

const mapStateToProps = state => {
  const isLoading = state.mediationReducer.isLoading.includes('GET_ASSIGNED_MEDIATIONS')
  || state.mmStatusReducer.isLoading.includes('CANCEL_MEDIATION_MEDIATOR');
  const assignedMediations = state.mediationReducer.assignedMediations;
  const cancelledMediationStatus = state.mmStatusReducer.callSuccessful.includes('CANCEL_MEDIATION_MEDIATOR');
  return { isLoading, assignedMediations, cancelledMediationStatus }
}

export default connect(
  mapStateToProps,
  {
    fetchAssignedMediations,
    cancelConfirmedMediation,
    fetchConfirmedMediationsReport
  }
)(AssignedMediations)