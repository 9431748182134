import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import Spacer from 'components/layout/Spacer';
import { Field, reduxForm } from "redux-form";
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { reduxFormInput } from 'components/form/ReduxForm';
import { required, email, mobileNumber } from 'util/validationUtil';

const EditScheduler = (props) => {

  const editSubmit = useCallback( data => {
    props.update(data);
  }, []);

  return (
    <Modal className="body-black"
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5>Edit Scheduler</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Container fluid>
            <Spacer size={3} />
            <Form id="editSchedulerUI" className="w-100" onSubmit={props.handleSubmit(editSubmit)}>
              <Row>
                <Col xs="12" sm="9">
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      First Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="first_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Last Name:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required]}
                        name="last_name" 
                        type="text" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Email Address:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, email]}
                        name="email" 
                        type="email" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Phone Number:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        validate={[required, mobileNumber]}
                        name="phone_number" 
                        type="text" 
                        placeholder=""
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="align-items-center">
                    <Form.Label column xs="12" sm="4">
                      Active:
                    </Form.Label>
                    <Col xs="12" sm="8">
                      <Field 
                        className="form-control" 
                        component={reduxFormInput}
                        name="is_active" 
                        type="checkbox" 
                        placeholder="" 
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="12" sm="4">
                  <Spacer size={2} />
                  <Button type="submit" className="primary" variant="primary-alt" size="sm">
                    Save
                  </Button>
                  <Spacer size={2} />
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const connectedReduxForm = reduxForm({
  form: 'editScheduler',
  enableReinitialize: true,
})(EditScheduler);
  
export default connect()(connectedReduxForm)