export const saveValueInLocal = (key, value) => {
  if (typeof Storage !== 'undefined') {
    localStorage.setItem(key, value);
  }
}

export const removeValueFromLocal = (key) => {
  if (typeof Storage !== 'undefined') {
    localStorage.removeItem(key);
  }
}

export const getValueFromLocal = (key) => {
  // console.log('getValueFromLocal key: '+key);
  if (typeof Storage !== 'undefined') {
    // console.log('Storage not undefined. calling getItem key: '+key);
    // console.log(JSON.stringify(localStorage));
    return localStorage.getItem(key);
  }
}

export const destroyLocal = () => {
  if (typeof Storage !== 'undefined') {
    localStorage.clear();
  }
}