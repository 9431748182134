import { actionTypes } from 'redux/actions/actionTypes';
import { saveValueInLocal } from 'util/storageUtil';
import constants from 'config/constants';

const initialState = {
  isLoggingIn: false,
  loginFailed: false,
  data: '',
  userId: 0
}


export default function(state = initialState, action) {

  // console.log("Current directory:"+ __dirname);
  // console.log('authreducer action')
  // console.log('action: '+JSON.stringify(action))
  switch (action.type) {
  case actionTypes.USER_LOGIN_REQUEST: 
    return {
      isLoggingIn: true,
      loginFailed: false
    }
  case actionTypes.USER_LOGIN_SUCCESS:
    // console.log("is_active: "+action.payload.is_active);
    saveValueInLocal(constants.JWT_ACCESS_KEY, action.payload.access_token);
    saveValueInLocal(constants.EXPIRES_IN, action.payload.expires_in);
    saveValueInLocal(constants.USER_ID, action.payload.id);
    saveValueInLocal(constants.NAME, action.payload.name);
    saveValueInLocal(constants.ROLE, action.payload.role);
    saveValueInLocal(constants.IS_PASSWORD_SET, action.payload.is_password_set);
    saveValueInLocal(constants.IS_ACTIVE, action.payload.is_active===1);
    return {
      isLoggingIn: false,
      loginFailed: false
    }
  case actionTypes.USER_LOGIN_FAILURE:
    return {
      isLoggingIn: false,
      loginFailed: true,
      error: action.payload
    }
  case actionTypes.FORGOT_PASSWORD_RESET: 
    return {
      isLoggingIn: true,
      loginFailed: false
    }
  case actionTypes.FORGOT_PASSWORD_RESET_SUCCESS: 
    return {
      isLoggingIn: false,
      loginFailed: false,
      data: action.payload
    }
  case actionTypes.FORGOT_PASSWORD_RESET_FAILURE:
    return {
      isLoggingIn: false,
      loginFailed: true,
      error: action.payload
    }
  case actionTypes.CHECK_PASSWORD_TOKEN: 
    return {
      isLoggingIn: true,
      loginFailed: false
    }
  case actionTypes.CHECK_PASSWORD_TOKEN_SUCCESS: 
    return {
      isLoggingIn: false,
      loginFailed: false,
      userId: action.payload.data
    }
  case actionTypes.CHECK_PASSWORD_TOKEN_FAILURE:
    return {
      isLoggingIn: false,
      loginFailed: true,
      error: action.payload
    }
  default: 
    return state
  }
}