import React from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import groupBy from 'json-groupby';
import moment from 'moment';
import 'styles/calendar.scss';
import tick from 'images/Tick.png';
import Popover from 'react-bootstrap/Popover';
import blueicon from 'images/blueicon.png';
class Calendar extends React.Component {
  state = {
    isActive: this.props.isActive,
    currentMonth: (this.props.currentYear+this.props.monthSelected+"01"),
    selectedDate: moment(),
    textareaValue:'',
    date:'',
    eventOff:'',
    tooltipStyle:'none',
    hover:false,
    showRemoveModal:false,
    id:'',
    dateObject: moment(),
    removeAvailability:false,
    editAvailability:false,
    previousDateSelected:false,
    dateClicked:'',
    textAreaChange:false
  };
 
  renderHeader() {
    return (
      <div style={{borderStyle: "solid",
        borderWidth: "1px"}} className="row flex-middle">
        <div className="col-end">
          <div onClick={()=>this.prevMonth()}>
            <div  className="arrow-style-left">
              <div className="arrow-left">
              </div>
            </div>
          </div>
        </div>
        <div className="col-calendar dateHeader">
          <span className="headers">{moment(this.props.currentMonth!==''?this.props.currentMonth:this.state.dateObject,'YYYY-MMM-DD').format('MMMM YYYY')}</span>
        </div>
        <div onClick={()=>this.nextMonth()}>
          <div className="arrow-style-right" >
            <div className="arrow-right" >
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderDays() {
    const days = [];
    let week=moment.weekdaysShort()
    // console.log(JSON.stringify(week))
    week.forEach((day, index) => {
      days.push(
        <div className="col-calendar col-center" key={day}>
          {day}
        </div>
      );
    });
    return <div className="days row">{days}</div>;
  }
 
  /**Sets the input text in textareaValue */
  handleTextareaChange=(event)=> {
    this.setState({textareaValue: event.target.value,
      textAreaChange:true});
  }

  okButton =()=>{
    this.setState({
      tooltipClose:true
    })
    document.body.click()
  }
  
  deleteAvailability =()=>{
    this.props.deleteAvailability(this.state.id)
    document.body.click()
  }
      
  renderCells() {
    const { selectedDate } = this.state;
    const monthStart =this.props.currentMonth!==''? moment(this.props.currentMonth,'YYYYMMMDD').startOf("month"): moment(this.state.dateObject).startOf("month")//startOfMonth(this.props.currentMonth);
    const monthEnd = this.props.currentMonth!==''? moment(this.props.currentMonth,'YYYYMMMDD').endOf("month"):  moment(this.state.dateObject).endOf("month")// endOfMonth(monthStart);
    const startDate = moment(monthStart).startOf("week")
    const endDate = moment(monthEnd).endOf("week")
    const rows = [];
    let dateArray=[];
    let uniqueSelectedItem;
    if(this.props.selectedMonthItem!==undefined){
      this.props.selectedMonthItem.forEach((item, index) => {
        if(item!==undefined){
          dateArray.push(item.date)
        }
      });
    }
    let uniqueArray=Array.from(new Set(dateArray))
    let groupArrays
    /**By mediator view in view mediator, mediator names cannot repeat in a particular day */
    /**start */
    if(this.props.selectedMonthItem!==undefined && this.props.isAdmin===true && this.props.byMediator===false){
      let newData= this.props.selectedMonthItem.map(item=>{
        return {
          date:item.date,
          name:item.name
        }
      })
      
      let jsonObj=newData.map(JSON.stringify);
      let unique= new Set(jsonObj);
      uniqueSelectedItem=Array.from(unique).map(JSON.parse)
      let groupArrays2=groupBy(uniqueSelectedItem,['date'],['name'])
      groupArrays = Object.keys(groupArrays2).map((date) => {
        return {
          date,
          name: groupArrays2[date]
        };
      });
    }
    console.log('state: '+JSON.stringify(this.state))
    console.log('props: '+JSON.stringify(this.props))
    if (this.props.isAdmin!==true){
      console.log('props.isAdmin!==true');
    } else{
      console.log('props.isAdmin true');
    }
    if (this.props.byMediator!==true){
      console.log('props.byMediator!==true');
    } else{
      console.log('props.byMediator true');
    }
    if (this.state.showRemoveModal!==true){
      console.log('state.showRemoveModal!==true');
    } else{
      console.log('state.showRemoveModal true');
    }
    if (this.state.removeAvailability!==true){
      console.log('state.removeAvailability!==true');
    } else{
      console.log('state.removeAvailability true');
    }
    if (this.state.editAvailability!==true){
      console.log('state.editAvailability!==true');
    } else{
      console.log('state.editAvailability true');
    }
    if (this.state.isActive!==true){
      console.log('state.isActive!==true');
    } else{
      console.log('state.isActive true');
    }
    if (this.state.onDateClick!==true){
      console.log('state.onDateClick!==true');
    } else{
      console.log('state.onDateClick true');
    }
    if (this.state.previousDateSelected!==true){
      console.log('state.previousDateSelected!==true');
    } else{
      console.log('state.previousDateSelected true');
    }
    // .state.removeAvailability===true &&  this.state.editAvailability
    // console.log("month diff: "+moment().diff(moment(this.state.dateClicked), 'month'))
    /**End */
    // console.log('dateArray: '+JSON.stringify(dateArray))
    // console.log('selectedDate: '+this.state.selectedDate)
    let days = [];
    let day = startDate;
    let formattedDate = "";
    let word=`You may not change your
    availability for a day on which
    a mediation is already assigned to you.If you cannot accept this 
    mediation please decline the 
    mediation or call the ADR office
    at 404-612-4549 to speak with an ADR staff member`;
    // console.log('selectedDateFormatted: '+moment(this.state.selectedDate).format('YYYY-MM-DD'))
    while (day <= endDate )  {
   
      for (let i = 0; i < 7; i++) {
        formattedDate = moment(day).format('DD');
        const cloneDay = day;
        const data_tip=moment(cloneDay).format('YYYY-MM-DD')
        // console.log('data_tip: '+data_tip)
        
        
        if(moment(cloneDay).format('ddd')==='Mon' || 
           moment(cloneDay).format('ddd') ==="Tue"||
           moment(cloneDay).format('ddd')==='Wed' || 
           moment(cloneDay).format('ddd') ==="Thu" ||
           moment(cloneDay).format('ddd')==='Fri'){
          days.push(
            <OverlayTrigger
              trigger="click"
              key={formattedDate}
              placement={"top"}
              rootClose={true}
              defaultShow={false}
              overlay={
                this.state.previousDateSelected===true?
                  <Popover id={`popover-positioned-${"placement"}`}>
                    <Popover.Title>{moment(this.state.selectedDate).format( "MMMM DD,YYYY")} <a className="close"  onClick={()=>this.okButton()}
                    ></a></Popover.Title>
                    <Popover.Content>
                      <strong>Cannot perform any action for past dates</strong>
                    </Popover.Content>
                  </Popover> :
                  
                  (this.state.isActive===true && this.state.onDateClick===true) ?
                    this.props.isAdmin!==true || this.props.byMediator===true ?
                      <Popover id={`popover-positioned-${"placement"}`}>
                        <Popover.Title >{moment(this.state.selectedDate).format( "MMMM DD,YYYY")} <a className="close" onClick={()=>this.okButton()}
                        ></a></Popover.Title>
                        <Popover.Content>
                          <strong>
                            { moment().diff(moment(this.state.dateClicked), 'month')<-1 && this.props.byMediator!==true?
                              <>Cannot plan availability for more than 60 days
                                <button className="buttonPopUp" 
                                  onClick={()=>this.okButton()}
                                > OK</button>
                              </>:
                              dateArray.includes(this.state.selectedDate!==undefined?moment(this.state.selectedDate).format('YYYY-MM-DD'):data_tip)?
                                this.props.byMediator===true || this.state.showRemoveModal===true?
                                  <>
                                    { this.handleAvailabiltity(cloneDay)}
                                    <div style={{marginLeft: "65px",
                                      marginTop: "-42px"}}>
                                      <button className="buttonPopUp" onClick={()=>{
                                        this.setState({
                                          removeAvailability:true,
                                          editAvailability:false
                                        })
                                      }}>Remove</button> 
                                    </div>
                                    {this.state.removeAvailability===true &&  this.state.editAvailability!==true?<div> Are you sure you want to remove the availability?
                                      <button className="buttonPopUp" 
                                        onClick={()=>this.deleteAvailability()}
                                      > OK</button>
                      &nbsp;
                                      <button className="buttonPopUp" 
                                        onClick={()=>this.okButton()}
                                      > Cancel</button></div>:""}
                                  </>
                                  :
                                  <><div>{word} </div>
                                    <button className="buttonPopUp" 
                                      onClick={()=>this.okButton()}
                                    > OK</button>
                                  </>:this.handleAvailabiltity()
                   
                            }</strong>
                        </Popover.Content>
                      </Popover>:<div></div> :<div></div>
              }
            >
              <div
                className={`col-calendar cell ${
                  !moment(day).isSame( monthStart,'month')
                    ? "disabled"
                    : moment(day).isSame( selectedDate,'day')
                      ? "selected"
                      : ""
                }`}
                key={day}
                onClick={() => this.props.selectedMonthItem!==undefined?this.props.byMediator===false?this.props.byMediatorClick(cloneDay):this.onDateClick(cloneDay):false}
              >
                {(this.props.isAdmin!==true || this.props.byMediator===true) && 
                this.props.selectedMonthItem!==undefined?uniqueArray.map(item=>{
                    if(item!==undefined){
                      if(item===moment(day).format('YYYY-MM-DD')){
                        return( <div style={{marginLeft: "10px",
                          marginTop: "27px"}}>
                  
                          <img 
                            src={tick} alt="Logo" /> <div ></div>
                        </div>)
                      }
                    }
                  }):""}
                <span className="number">{formattedDate}</span>
                {
                  this.props.isAdmin === true && this.props.selectedMonthItem!==undefined && this.props.byMediator===false?
                    groupArrays.map(item=>{
                      if(item!==undefined){
                        if(item.date=== moment(day).format('YYYY-MM-DD')){   
                          if(item.name.name.length>3){
                            return(
                              <OverlayTrigger
                                placement="top1"
                                delay={{ show: 250, hide: 400 }}
                                overlay={ <Tooltip id="button-tooltip" >
                                  {item.name.name.toString()}
                                </Tooltip>}
                              >
                                <div>
                                  <span className="availabilityText">{item.name.name[0].toString()}
                                    <br />{item.name.name[1].toString()}<br />{item.name.name[2].toString()}...<span style={{color:"blue"}}>see more</span></span>
                                  <span className="icon">
                                    <div className="availabilityText">{item.name.name.length}</div></span>
                                </div>
                              </OverlayTrigger>
                            )
                          }
                          else{
                            return(
                              <div>
                                <span className="availabilityText">{item.name.name[0]}<br/>
                                  {item.name.name[1]}<br/>{item.name.name[2]}</span>
                                <OverlayTrigger
                                  placement="top"
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={ <Tooltip id="button-tooltip" >
                                    {item.name.name.toString()}
                                  </Tooltip>}
                                >
                                  <span className="icon">
                                    <img src={blueicon} alt="Logo" />
                                    <div style={{position: "absolute",
                                      top: "43px",left: "27px",transform: "translate(-6px, -14px)"}}>{item.name.name.length}</div></span></OverlayTrigger>
                              </div>
                            )
                          }
                        }
                      }
                    }):""
                }
              
              </div></OverlayTrigger>
          );}
        day = moment(day).add(1, 'd');
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }/**Tooltip functionality ends here */

  handleAvailabiltity(){
    let comment 
    let data= this.props.selectedMonthItem.filter(item=>item!==undefined)
    data.map(item=>{
      if( this.state.dateClicked === item.date){
        comment=item.comments
      }
    })
    if(this.state.textAreaChange===true){
      comment=this.state.textareaValue
    }
    return(<>
      <div>
        <strong style={{paddingLeft: "18px"}}>Available</strong></div>
      <textarea style={{ marginLeft: "10px",marginRight: "20px"}} value={comment}
        onChange={this.handleTextareaChange}/>
      <div>
        <button className="buttonPopUp" 
          onClick={this.saveButton}> Save</button></div>
    </>
    )

  }
  /**On any date click,the control comes here with the clicked date */
  onDateClick = (day) => {
    let dateFormat= moment(day).format('YYYY-MM-DD')
    // console.log('date clicked: '+dateFormat)
    // console.log('selectedMonthItem: '+JSON.stringify(this.props.selectedMonthItem))
    let item
    let res
    this.setState({
      onDateClick:true,
      dateClicked:dateFormat,
      removeAvailability:false
    })
    let dayCalculation= moment(day).diff(moment(), 'day')
    if(dayCalculation<0){
      this.setState({
        previousDateSelected:true
      })
    }
    else{
      this.setState({
        previousDateSelected:false
      })
      for(item in this.props.selectedMonthItem){
        if(this.props.selectedMonthItem[item]!==undefined && this.props.selectedMonthItem[item].date===dateFormat){
       
          let hours = moment(day).diff(moment(), 'day')
          // console.log('hours: '+hours)
          if(hours<0){
            this.setState({
              previousDateSelected:true
            })
          }
          if((hours!==0 || this.props.byMediator===true)){
            if ( this.props.selectedMonthItem[item].assigned_mediations.length > 0 ){

            } else {
              // console.log('setting showRemoveModal to true ')
              res=true
              this.setState({
                showRemoveModal:true,
                id:this.props.selectedMonthItem[item].id,
                textareaValue:''
              })
            }
          }
        }
      }
      if(res!==true){
        // console.log('setting showRemoveModal to false ')
        this.setState({
          showRemoveModal:false
        })
      }
      if(moment(day).format('MMM')===this.props.monthSelected){
        this.setState({
          selectedDate: day
        })
      }
    }
  };
  /**Arrow click to change month to next month */
  nextMonth = () => {
    let nextMon=this.props.currentMonth!==''?this.props.currentMonth:this.state.dateObject
    this.setState({
      currentMonth: moment(nextMon).add(1, 'month')
    });
    this.props.monthChangedviaArrowClick(moment(nextMon,'YYYYMMMDD').add(1, 'month'))
  };
  /**Arrow click to change month to previous month */
  prevMonth = () => {
    let currMon=this.props.currentMonth!==''?this.props.currentMonth:this.state.dateObject
    this.setState({
      currentMonth: moment(currMon).subtract(1, 'month')
    });
    this.props.monthChangedviaArrowClick( moment(currMon,'YYYYMMMDD').subtract(1, 'month'))
  };
  /**Save button of tooltip which saves the comments of the mediator  */
  saveButton = () => {
    let editId
    let date=moment(this.state.selectedDate).format('YYYY-MM-DD')
    let dateArray=[]
    this.setState({textAreaChange:false})
    if(this.props.selectedMonthItem!==undefined){
      this.props.selectedMonthItem.map(item=>{
        if(item!==undefined){
          dateArray.push(item.date)
        }
      })
    }
    let uniqueArray=Array.from(new Set(dateArray))
    uniqueArray.map(item=>{
      if(item===date){
        editId=this.state.id
      }
    })
    /**Save api call */
    this.props.newMediatorAvailability(date,this.state.textareaValue,editId)
    document.body.click()

  }

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        { this.renderCells()}

      </div>
    );
  }
}

export default Calendar;